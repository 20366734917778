import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {

      async jobs() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "entries/jobs", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async recentJobs() {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "entries/jobsRecent", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async noOwnerJobs() {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "project-job/no-owner/list", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async noOwnerJobsSetOwner(data) {
            data.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "project-job/no-owner/set-owner", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
     
}