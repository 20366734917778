<template>
    <Loader :loader="loader" />
   
    <div class="inner-wrap2">
      <!-- <p style="font-size: 28px; font-family: 'Anton-Regular';">coming soon</p> -->
     
     <h3>Completed User work</h3>
      <div class="criteria">
        <div class="control-wrap">
            <span>Month: &nbsp;</span>
            <date-picker v-model="month" 
              @internalModelChange="onDateChange()"
              monthPicker
              autoApply
              :dark="settDarkMode"
              :clearable="false"               
              >
             <template #input-icon>
                      &nbsp;&nbsp;<i class="far fa-calendar-alt"></i>
             </template>
          </date-picker>
            <span>Pagination: </span>
            <label class="rad-label">
                  <input type="checkbox" class="rad-input" name="pagination" v-model="pagination" />
                  <div class="rad-design"></div>
          </label>
        </div>

        <div class="control-wrap excel">
            <vue-excel-xlsx class="d-btn ex-btn"
            :data="table1rows"
            :columns="table1columns"
            :file-name="filename"
            :file-type="'xlsx'"
            :sheet-name="'UsersByMonth'"
            :disabled="table1rows === null"
            title="export to Excel"
            >
            <i class="fas fa-file-excel"></i>
          </vue-excel-xlsx>
        </div>
      </div>

      <div class="center-pan">

        <vue-good-table
            :columns="table1columns"
            :rows="table1rows"
            :row-style-class="rowStyleClass"
            :line-numbers="false"
            :search-options="{enabled: true}"
            :sort-options="{enabled: true}"  
            :pagination-options="{
              enabled: pagination,
              mode: 'records'
            }" 
                          
          >
            <template v-slot:emptystate>
                No data
            </template>
            
        </vue-good-table>
      </div>
        
    </div>
 
  
</template>

<script>
import shared from "@/shared";
import moment from "moment";
import reportService from "@/services/reports.js";
import Loader from '../../components/Loader.vue';
// import { DoughnutChart } from 'vue-chart-3';
// import { BarChart } from 'vue-chart-3';
// import { Chart, registerables } from "chart.js";

//Chart.register(...registerables);

export default {
  components: {
      Loader, 
      //DoughnutChart, 
      //BarChart 
  },
  name: "App",
  
  

  data: function () {
    return {
      month: {
            month: new Date().getMonth(),
            year: new Date().getFullYear()
      },
      showNav: true,
      error: null,
      loader: false,
      settDarkMode: false,
      pagination: false,
      filename: 'filename',
     
      table1columns: [
        {
          label: 'Team',
          field: 'team',
          tdClass: 'text-f14'
        },
        {
          label: 'Name',
          field: 'name',
          tdClass: 'text-f14'
        },
        {
          label: 'Working hours',
          field: 'wh',
          tdClass: 'text-f14'
        },
        {
          label: 'Hours',
          field: 'hours',
          tdClass: 'text-f14'
        },
        {
          label: '[%]',
          field: 'prc',
          type: 'decimal',
          tdClass: this.tdClassFn
        }
       
      ],
      table1rows: []
      
    };
  },
 
  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();

    this.uFnCheck = shared.uFnCheck;
    if (!this.uFnCheck(7)) {
      this.CookieRemove();    
    }

    this.getSettingFromArr = shared.getSettingFromArr;
    this.setTheme = shared.setTheme;

    this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
    this.setTheme(this.settDarkMode);

    // if(this.$store.getters.userData.role !== 'Admin') {
    //     this.$router.push({ name: "Dashboard" });
    // }

    this.filename = 'UsersWork-month-'+this.month.year+'-'+this.month.month+'-'+moment().format("YYMMDD.HHmmss");

    this.onDateChange();
    

   
  },
   
  methods: {

  
    onDateChange() {
      this.loader = true;
      this.filename = 'UsersWork-month-'+this.month.year+'-'+this.month.month+'-'+moment().format("YYMMDD.HHmmss");

     

      const data = {
        month: this.month.month + 1,
        year: this.month.year
      };

      console.log('data change');
      //console.log(data);
      

      reportService
      .rap5(data)
      .then((response) => {

        if (response.status === 200) {
         this.table1rows = response.data;
          // console.log("----user list----");
          // console.log(this.usersList);
           for (const i of this.table1rows) {

            const newName = i.name.split('#');
            i.name = newName[0];
            i.status = newName[1];            
          }
          this.table1rows = this.table1rows.filter(function(el) { return !(el.hours == 0 && el.status == 'Inactive'); });
        }
        
      })
      .catch((e) => {
        this.error = e.message || "Something went wrong!";
        
        
        if (e.response.status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        
        //this.tableArr();

      });

    },

    rowStyleClass(row) {
      return row.name == '-Total-' ? 'cl-total' : (row.status == 'Inactive' ? 'cl-row-inactive' : 'cl-row');
    },

    tdClassFn(row) {

      //  console.log("----row----");
      //  console.log(row);


      if (row.prc == 0) {
        return 'red1-class';
      } else if (row.prc > 0 && row.prc < 20) {
        return 'red2-class';
      } else if (row.prc >= 20 && row.prc < 60) {
        return 'yellow-class';
      } else {
        return 'green-class';
      }
    },

   
    // tableArr() {
    //   //this.table1rows = this.rapJobsData;

    //   if (this.table1rows.length > 1) {

    //     let sum = 0.0;
    //     this.table1rows.forEach(element => {
    //       sum = sum + element.hours;
          
    //     });
    //     this.table1rows.push( { "jobNo": "", "jobName": "<b>Total</b>", "hours": "<b>"+sum+"</b>" });
    //   }

    // },

    
 

  },
  computed: {
    
  }

}
</script>

<style >

.inner-wrap2 {
  text-align: center;

}

.inner-wrap2 h3 {
  font-family: 'Anton-Regular';
  font-size: 22px;
  letter-spacing: 2px;
}

.inner-wrap2 .dp__main {
  width: 260px;
}


.inner-wrap2 .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 20px;
  justify-content: center;
  margin-bottom: 26px;
}


.inner-wrap2 .criteria span { 
  margin-left: 10px; 
  }

.inner-wrap2 .vue-select {
  border-radius:initial;
  border-radius: 4px;
  z-index: 2;
  border: 1px solid #e3e3e3;
  background-color: white;
}

.inner-wrap2 .vue-input {
  padding: 10px 6px 10px 6px;
}
.inner-wrap2 .vue-input input {
  background-color: transparent;
  font-size: 16px;
}

.inner-wrap2 .vue-dropdown {
  border: 1px solid #e3e3e3;
}

.inner-wrap2 .vue-input .icon.arrow-downward  {
  border-width: 8px 5px 0;
}

.inner-wrap2 .vue-dropdown-item.highlighted {
    background-color: #fff8e3;
}

.inner-wrap2 .vue-dropdown-item.selected.highlighted {
    background-color: #fceec2;
}

/* .dialog-box.edit .vue-select {
  width: 100%;

} */


.inner-wrap2 .center-pan {
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    margin: 10px;
    margin-left: 20px;
    padding: 18px;
    min-height: 380px;
   
}

.inner-wrap2 .text-f14 {
    font-size: 14px;
}

.inner-wrap2 table.vgt-table {
  font-size: 14px;
}

.inner-wrap2 table.vgt-table .cl-total {
  background-color: #fafaf1;
}

.inner-wrap2 table.vgt-table td.red1-class {
  background-color: #ffeded;
}

.inner-wrap2 table.vgt-table td.red2-class {
  background-color: #fff5ed;
}

.inner-wrap2 table.vgt-table td.yellow-class {
  background-color: #ffffed;
}

.inner-wrap2 table.vgt-table td.green-class {
  background-color: #efffed;
}


.inner-wrap2 .vgt-wrap__footer {
  padding: 10px 16px;
}

.inner-wrap2 .vgt-wrap__footer .footer__navigation, 
.inner-wrap2 .vgt-wrap__footer .footer__row-count__label,
.inner-wrap2 .vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}


.control-wrap {
  display: flex;
  justify-content:flex-end;
  flex-grow: 1;
  /* align-items: center; */
  align-items: baseline;
}


.control-wrap .rad-label {
    padding: 0px 0 0 6px;
}

.control-wrap.excel {
  padding-right: 5px;
}

.control-wrap.excel .d-btn {
  width: auto;
  padding: 7px 18px;
  font-size: 18px;
}


</style>