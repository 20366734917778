import { createStore } from "vuex";

export default createStore({
      state: {
        accessToken: null,
        userId: null,
        userName: null,
        role: null,
        team: null,
        teamId: null,
        agency: null,
        agencyId: null,
        authHeader: null,
        userSettings: [],
        userFunction: [],
        userSettPropLen: 8,
        currYear: new Date().getFullYear(),
        version: '0.86',
        logoColor: "#c25a10",
        apiUrl: process.env.VUE_APP_API_URL
        //apiUrl: 'https://ts-api-dev.liquidthread.pl/'
        //apiUrl: 'https://ts-api.liquidthread.pl/'
      },
      mutations: {
        setUser(state, payload) {
          state.accessToken = payload.accessToken;
          state.userName = payload.userName;
          state.role = payload.role;
          state.team = payload.team;
          state.teamId = payload.teamId;
          state.agency = payload.agency;
          state.agencyId = payload.agencyId;
          state.userId = payload.userId;
          state.authHeader = { 
              "Content-Type": "application/json" ,
              "Authorization": "Bearer "+payload.accessToken
            }
          console.log('state.authHeader');
        },
        
        setUserSettings(state, arr) {
          state.userSettings = arr;
        },

        setUserFunction(state, arr) {
          state.userFunction = arr;
          console.log('state.userFunction');
        },

        setVersion(state, val) {
          state.version = val;
        },

        setLogoColor(state, val) {
          state.logoColor = val;
        }

        
        
      },
      actions: {},
      getters: {
        userData(state) {
          let isToken = false;
          if(state.accessToken) {
            isToken = true;
          }
    
          return { 
            accessToken: state.accessToken,
            isAccessToken: isToken,
            userName: state.userName,
            role: state.role,
            team: state.team,
            teamId: state.teamId,
            agency: state.agency,
            agencyId: state.agencyId,
            userId: state.userId
            
          };
        },

        userSettings(state) {
          return state.userSettings;
        },

        userFunction(state) {
          return state.userFunction;
        },

        getAuthHeader(state) {
          return state.authHeader;
        },

        getApiUrl(state) {
          return state.apiUrl;
        },

        getVersion(state) {
          return state.version;
        },

        getLogoColor(state) {
          return state.logoColor;
        },

        getUserSettPropLen(state) {
          return state.userSettPropLen;
        },

        getCurrYear(state) {
          return state.currYear;
        },

        
       
      },
    });