import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {

      async holidayList() {
           
            const headers = store.getters.getAuthHeader;
            
            const response = await axios.get(API_URL + "admin/holiday", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      
      async holidayAdd(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "admin/holiday", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async positionList(AgencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/position/"+AgencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async positionEdit(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "admin/positionManage", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async roleList() {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/role", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async teamList() {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/team", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async teamAgencyList(AgencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/team/"+AgencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async usersList() {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/user", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async usersAgencyList(AgencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/agencyUser/"+AgencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userGet(UserId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/user/"+UserId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      
      async userAdd(userData) {
            userData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "admin/user", userData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async userEdit(userData) {
            userData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "admin/user", userData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async jobList(yearId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/jobs/"+yearId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async jobAgencyList(yearId, agencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/agencyJobs/"+yearId+"/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async jobGet(jobId, agencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "admin/job/"+jobId+"/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },


      async jobAdd(jobData) {
            jobData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "admin/job", jobData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async jobEdit(jobData) {
            jobData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "admin/job", jobData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async clientList() {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "admin/client", {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async dashboard(agencyId) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "admin/dashboard/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async noJobsList(agencyId) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "admin/nojob/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async noJobEdit(noJobData) {
            noJobData.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "admin/nojob", noJobData, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async uniRap(data) {

            data.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "admin/report", data, {headers});
            return {
                  'status': response.status,
                  'data': JSON.parse(response.data)
            };
      },

     
}