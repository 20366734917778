<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>
 
    <div class="inner-wrap">
      <h3>No Owner Jobs</h3>
 
      <div class="wrap wrap-missing">
      
            <div class="form-container">

                  <div class="form-col max-column inner-wrap3">

                  <div class="no-record" v-if="noOwnerJobList.length === 0">
                        Should be happy. No Job numbers to match.
                  </div>

                  <div v-else>
                        <div class="form-info">
                        <p>Select appropriate records and assign the Owner</p>
                        </div>
                        <div class="criteria">
                        <div class="control-wrap">
                        <span>User: &nbsp;</span>
                        <vue-select  
                              v-model="selectedUser" 
                              :options="usersList" 
                              label-by="Name" 
                              value-by="Id"
                              placeholder="select Owner" 
                              searchable 
                              clear-on-select 
                              clear-on-close 
                              close-on-select                        
                              class="v-select"
                        > 
                              <template #dropdown-item="{ option }">
                              <div :class="'st-'+option.Status">{{ option.Name }}</div>
                              </template>
                        </vue-select>

                              <base-button class="d-btn d-edit" @click="saveForm()" title="Save" :disabled="selectedUser == null || selectedJobs.length === 0">
                              <i class="fas fa-save"></i>
                              </base-button>
                        </div>
                        <div class="save-msg">
                        {{statusMessage}}
                        </div>
                        </div>

                        <table>
                        <tr>
                        <th style="width: 4%;"> </th>
                        <th style="width: 8%;">Job No</th>
                        <th style="width: 21%;">Client Name</th>
                        <th style="width: 26%;">Job Name</th>
                        <th style="width: 12%;">SFM reg. User</th>
                        <th style="width: 12%;">SFM Owner</th>
                        <th style="width: 17%;">SFM Date</th>
                        </tr>
                        <tr v-for="(item) in noOwnerJobList" :key="item.id">
                              <td>
                              <label class="rad-label">
                              <input type="checkbox" class="rad-input" :id="'ch'+item.id" :value="item.id" v-model="selectedJobs">
                              <div class="rad-design"></div>
                              <!-- <div class="rad-text"></div> -->
                              </label>  

                              <!-- <input type="checkbox" :id="'ch'+item.id" :value="item.id" v-model="selectedJobs"> -->

                              </td>
                              <td><strong>{{item.jobNo}}</strong></td>
                              <td>{{item.clientName}}</td>
                              <td>{{item.jobName}}</td>
                              <td>{{item.registeredUser}}</td>
                              <td>{{item.sfmOwner}}</td>
                              <td style=" text-align: right;">From: {{item.sfmDateFrom}}<br>To: {{item.sfmDateTo}}</td>
                        </tr>

                        </table>


                  </div>


                  </div>

            </div>
      
            
      </div>
    </div>


  <!-- <nav-menu :showMenu="showMenu" ></nav-menu> -->
</template>

<script>
import shared from "@/shared";
import userService from "@/services/user.js";
import jobsService from "@/services/jobs.js";
import Loader from '../../components/Loader.vue';


export default {
 components: {Loader },
 name: "App",

 data: function () {
    return {
      loader: true,
      error: null,
      showMenu: false,
      showNav: false,
      statusMessage: null,
      noOwnerJobList: [],
      usersList: [],
      selectedJobs: [],
      selectedUser: null

      

    };
  },

  mounted() {
    this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
    this.CookieRemove = shared.CookieRemove;
    this.CookieCheckGoToLogin();
    //this.$store.commit('setAxiosState', 1);
    
      Promise.all([userService.usersList(), jobsService.noOwnerJobs()])
      .then(([usersList, noOwnerJobList]) => {
        if (usersList.status === 200) {

          // this.usersList = usersList.data;

          usersList.data.forEach((element) => {
            this.usersList.push({ Id: element.Id, Name: element.FirstName+' '+element.LastName+' - '+element.Team, Status: element.Status });
          });

          

          // console.log("----usersList----");
          // console.log(this.usersList);
        
        }

        if (noOwnerJobList.status === 200) {
          this.noOwnerJobList = noOwnerJobList.data;

          // console.log("----noOwnerJobList----");
          // console.log(this.noOwnerJobList);
         
        }

      })
      .catch((e) => {
        console.log("----error----");
        console.dir(e);

        this.error = e.message || "Something went wrong!";

        if (e.responses[0].status === 401) {
            this.CookieRemove();
        }
      })
      .finally(() => {
        this.loader = false;
        this.showNav = true;
        //this.$store.commit('setAxiosState', 0);
      });

   
  },


  methods: {

    
    saveForm() {
      // console.log("----saveForm----");

      let jobList = {};

      jobList = {
          ownerId: this.selectedUser,
          jobIdTab: this.selectedJobs.toString()
      };
            
   
      jobsService
        .noOwnerJobsSetOwner(jobList)
        .then((response) => {
         
          if (response.status === 200) {
            if (response.data.Code === "OK") {

              console.log('Saved!');
              this.statusMessage = 'Excellent! Saved!';
              this.refreshNoOwnerJobList();

              setTimeout(() => {
                this.statusMessage = null;
              }, 2000);

            } 
            else {
              this.error = response.data.Text;
              this.statusMessage = this.error;
              

            }
          }
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";
          this.statusMessage = this.error;
          

          if (e.response.status === 401) {
              this.CookieRemove();
          }
        });


    },

    refreshNoOwnerJobList() {

        jobsService
        .noOwnerJobs()
        .then((response) => {
         
          if (response.status === 200) {
            
            this.noOwnerJobList = [];
            this.noOwnerJobList = response.data;

          }
        })
        .catch((e) => {
          this.error = e.message || "Something went wrong!";
          this.statusMessage = this.error;
          

          if (e.response.status === 401) {
              this.CookieRemove();
          }
        });

    }

    
  },

}
</script>

<style scoped>

.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.6;
  z-index: 999;
}

.lds-hourglass {
  display: inline-block;

  width: 80px;
  height: 80px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid black;
  border-color: black transparent black transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}



.wrap.wrap-missing {
  flex-direction: column;
  padding-right: 62px;
}

/* .wrap.wrap-missing::before {
  content: ' ';
  display: block;
  position: absolute;
  left: 16px;
  top: 65px;
  width: 310px;
  height: 260px;
  opacity: 0.1;
  background-image: url('../../assets/question2.png');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  
} */

.wrap.wrap-missing::after {
    content: ' ';
    display: block;
    position: absolute;
    right: 15px;
    bottom: 20px;
    width: 240px;
    height: 200px;
    opacity: 0.1;
    background-image: url('../../assets/question1.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
}

.form-container {
    width: 100%;
    max-width: 1170px;
    min-height: 400px;
    margin: 0 auto;
    text-align: left;
    padding: 16px;
    padding-top: 26px;
    box-shadow: 0px 3px 16px -5px rgb(120 122 123);
    position: relative;
    background-color: white;
    display: flex;
}

.form-col {
  text-align: center;
  padding: 0 16px;
}

.form-col.max-column {
  width: 100%;
}

.form-col div {
  overflow-wrap: break-word;
  text-align: left;
  
}

.form-col .form-info {
  text-align: center;

}

.form-col .form-info p {
  margin-bottom: 8px;
  
}

.form-col .criteria {
  display: flex;
  align-items: center;
  margin: 0 auto;
  margin-top: 12px;
  justify-content: center;
  margin-bottom: 60px;
  position: relative;
}

.form-col .criteria .control-wrap {
  justify-content: center;
}

.form-col .criteria button {
  margin-left: 8px;
  font-size: 22px;
}


.form-col .vue-select {
  width: 470px;
}


.form-col .criteria .save-msg {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-size: 11px;
  text-align: center;
  font-weight: 800;
  color: #c25a10;
}





.form-col.max-column table,
.form-col.max-column table tr,
.form-col.max-column table tr td {
  border: none;
  font-size: 12px;
  font-weight: normal;
}

.form-col.max-column table {
  border-spacing: 0;
}


.form-col.max-column table tr th {
  font-weight: 800;
  color: #c25a10;
  text-align: center;
  padding-bottom: 16px;
}

.form-col.max-column table tr td {
  padding: 4px 2px;
}

.form-col.max-column table tr:nth-child(even) {
  background-color: #f9f9f9;
}


.form-col.max-column table tr .rad-label {
    padding: 3px 6px;
    justify-content: center;
}

.form-col.max-column table tr .rad-label {
    padding: 3px 6px;
    justify-content: center;
}

.form-col.max-column table tr .rad-design {
    width: 14px;
    height: 14px;
}


.form-col .no-record {
  text-align: center;
}

.missing-list-form {
  display: flex;
  flex-wrap: wrap;
  align-items: start;
}

.missing-list-form .form-item {
  margin-top: 5px;
}

.control-wrap .vue-dropdown-item .st-Inactive {
    color: #d9d9d9 !important;
}




.dark-mode .form-container {

    box-shadow: 0px 3px 16px -5px rgb(24 24 24);
    background-color: #1c2630;
}

.dark-mode .missing-list-form .vue-select {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
  color:#d1d1d1;
}

.dark-mode .missing-list-form .vue-select .vue-input {
  background-color: #2c3e50;
}

.dark-mode .vue-dropdown {
  border: 1px solid #5c5c5c;
  background-color: #2c3e50;
}

.dark-mode .control-wrap .vue-dropdown-item .st-Inactive {
    color: #808080 !important;
}

.dark-mode .vue-input input {
  background-color: #2c3e50;
}



.dark-mode .form-col.max-column table tr:nth-child(even) {
  background-color: #212121;
}


</style>