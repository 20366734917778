import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;

export default {

     
      async rap0(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/rap0", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap1jobs(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "dashbrd/jobs", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap1hours(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "dashbrd/hours", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap1clients(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "dashbrd/clients", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap2(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/rap2", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap3() {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/noJobs", {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap4(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/yearJobs", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap5(data) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/rap5", data, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap6(jobId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.post(API_URL + "reports/rap6/"+jobId, {}, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap6Hours(jobId) {

            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "reports/rap6-jobInfoHoursDay/"+jobId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      

      async rap7Jobs(year, team, agencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "reports/rap7-jobs/"+year+"/"+team+"/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap7Projects(year, team, agencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "reports/rap7-project/"+year+"/"+team+"/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },

      async rap8(year, team, agencyId) {

            const headers = store.getters.getAuthHeader;

            const response = await axios.get(API_URL + "reports/rap8/"+year+"/"+team+"/"+agencyId, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },


            
      // async uniRap(data) {

      //       const headers = store.getters.getAuthHeader;

      //       const response = await axios.post(API_URL + "finance/report", data, {headers});
      //       return {
      //             'status': response.status,
      //             'data': response.data
      //       };
      // },
      
     
}