<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>

      <base-dialog :show="showDialog" :title="dialogTitle" @close="showDialog=false">
            <template v-slot:content>
                  
                  <div class="inv-wrapper nwd-wrap j-wrap">
                        <div class="u-form-item">
                              <label>NoJob Id</label>
                              <div class="f-item">
                                    {{noJobData.NoJobId}}
                              </div>                                      
                        </div>
                        <div class="u-form-item">
                              <label>Description</label>
                              <div class="f-item">
                                    {{noJobData.Descr}}       
                              </div>                               
                        </div>
                        <div class="u-form-item">
                              <label>Registered User</label>
                               <div class="f-item">
                                     <vue-select 
                                          v-model="noJobData.RegUserId" 
                                          :options="users" 
                                          label-by="Name"
                                          value-by="Id"
                                          placeholder="select User"
                                          searchable 
                                          clear-on-select 
                                          clear-on-close 
                                          close-on-select />      
                              </div>        
                                                          
                        </div>
                        <div class="u-form-item">
                              <label>Registered date</label>
                              <div class="f-item">
                                    {{noJobData.Sysdate}}       
                              </div>                               
                        </div>
                         
                        <div class="u-form-item">
                              <label>Responsible User</label>
                              <div class="f-item">
                                     <vue-select 
                                          v-model="noJobData.RespUserId" 
                                          :options="users" 
                                          label-by="Name"
                                          value-by="Id" 
                                          placeholder="select User"
                                          searchable
                                          clear-on-select 
                                          clear-on-close 
                                          close-on-select />   
    
                              </div>                                       
                        </div> 

                        <div class="u-form-item">
                              <label>Match to</label>
                              <div class="f-item j-item">
                                     <vue-select 
                                          v-model="setJobId" 
                                          :options="jobs" 
                                          label-by="JobNoName"
                                          value-by="JobId"
                                          placeholder="select Job number"
                                          searchable 
                                          clear-on-select 
                                          clear-on-close 
                                          close-on-select >  


                                          <template #dropdown-item="{ option }">
                                                <div :class="this.$store.getters.getCurrYear == option.JobYear ? 'y-active':'y-inactive'">{{ option.JobNoName }}</div>
                                          </template>

                                     </vue-select> 
    
                              </div>                                       
                        </div>           

                  </div>
                  
            
            </template>

            <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="showDialog=false" title="Cancel"><i class="fas fa-times"></i></base-button>
                  <base-button class="d-btn d-edit" @click="onSaveClick()" title="Save"><i class="fas fa-save"></i></base-button>
            </template>
      </base-dialog>



      <div class="inner-wrap3 nwd-wrap">
     
            <h3>Missing Jobs</h3>
                  <div class="criteria">
                        <div v-if="uFnCheck(11)" class="control-wrap">
                              <span>Agency: &nbsp;</span>
                              <vue-select 
                              v-model="agencyId" 
                              :options="agencies" 
                              label-by="Description" 
                              value-by="Id" 
                              :blur="onChange"
                              close-on-select />                               
                        </div>  
                       
                  </div>

            <div class="center-pan">
                  <vue-good-table
                        :columns="table1columns"
                        :rows="table1rows"
                        :line-numbers="false"
                        :search-options="{enabled: true}"
                        :sort-options="{enabled: true}"  
                        :pagination-options="{
                              enabled: false,
                              mode: 'records'
                        }" 
                                    
                  >
                        <template v-slot:emptystate>
                        No data
                        </template>

                        <template v-slot:table-row="props">
                              <div v-if="props.column.field == 'Action'">
                                          <button title="Edit" class="d-btn" :name="props.row.NoJobId" @click="onBtnClick(props.row.NoJobId)"><i class="fas fa-edit"></i></button>                                         
                              </div>
                              <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                              </span>
                        </template>
                        
                  </vue-good-table>
            </div>        

      </div>
  
</template>

<script>
import shared from "@/shared";
// import moment from "moment";
import Loader from '../../components/Loader.vue';
import adminService from "@/services/admin.js";
//import jobsService from "@/services/jobs.js";



export default {

      components: {
            Loader,           
      },

      data: function () {

            return {
                  
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  dialogTitle: '',
                  settDarkMode: false, 
                  selectedNoJobId: null, 
                  setJobId: null,    
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],  
                  users: [],
                  jobs: [],
                  table1columns: [
                  {
                  label: 'NoJob Id',
                  field: 'NoJobId',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Description',
                  field: 'Descr',
                  tdClass: 'text-f14'
                  },
                  {
                  label: 'Registered User',
                  field: 'RegUserName',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Registered date',
                  field: 'Sysdate',
                  tdClass: 'text-f12'
                  },
                  {
                  label: 'Responsible User',
                  field: 'RespUserName',
                  tdClass: 'text-f12'
                  },
                  {
                  label: '',
                  field: 'Action',
                  tdClass: 'text-center',
                  sortable: false,
                  width: '64px',
                  }
                 
                  ],
                  table1rows: [],
                  noJobData: {
                        NoJobId:0,
                        Descr: "",
                        RegUserId: 0,
                        RegUserName:"",
                        RespUserId: 0,
                        RespUserName:"",
                        Sysdate: ""                       
                  }
                  
            };
      },

      created() {
            this.uFnCheck = shared.uFnCheck;
            this.CookieRemove = shared.CookieRemove;
      },


      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.CookieCheckGoToLogin();

            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;


            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);


            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                         setTimeout(() => {                 
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                              this.onChange();                          
                              
                              
                         },250);
                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  });

            
            //this.year = this.$store.getters.getCurrYear;
            //this.onChange();
            //this.usersList();
            //this.jobList();
            
      },

      methods: {

            usersList() {

                   adminService
                  .usersAgencyList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                                               
                              this.users = response.data;

                              this.users.forEach((element, index) => {
                                    this.users[index].Name = element.FirstName+' '+element.LastName;
                              });

                              //this.jobOwners.unshift({Id: 0, Name: '--Select--'});
                              
                              //  console.log("++++this.users+++");
                              //  console.log(this.users);
                  }
                  
                  })
                  .catch((e) => {
                        this.error = e.message || "Something went wrong!"; 

                        console.log("----błąd!!!!!!----");  
                        console.log(this.error);                        
                 
                  })
                  .finally(() => {
                        this.loader = false;
                  
                   
                  });

            },

            jobList() {

                  adminService
                  .jobAgencyList(0, this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                       
                              this.jobs = response.data;

                        //   console.log("----this.jobs----");
                        //   console.log(response.data);                      
                                          
                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";   
                  console.log("----błąd!!!!!!----");  
                  console.log(this.error);                        
                 
                  })
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });
            },

            
           


            onBtnClick(noJobId) {

                  // console.log('noJobId');
                  // console.log(noJobId);

                  this.selectedNoJobId = noJobId;
                  this.setJobId = null;

                  let tmpTab = [];

                  tmpTab = this.table1rows.filter(function (item) {
                        return item.NoJobId === noJobId;
                  });

                  this.noJobData = tmpTab[0];

                  // console.log('this.noJobData');
                  // console.log(this.noJobData);

                  this.showDialog = true;
                  this.dialogTitle = 'Id: '+this.selectedNoJobId;

            },

            onChange() {
                  this.loader = true;
                  
                  console.log('data change');

                  this.usersList();
                  this.jobList();
                  
                  adminService
                  .noJobsList(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {          
                       
                              this.table1rows = response.data;

                        //console.log("----table1rows----");
                        // console.log(this.table1rows);
                       
                                          
                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";   
                  console.log("----błąd!!!!!!----");  
                  console.log(this.error);                        
                 
                  })
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });

            },


            onSaveClick() {                 

                  let data = {
                        noJobId: this.selectedNoJobId,
                        regUserId: this.noJobData.RegUserId,
                        respUserId: this.noJobData.RespUserId,
                        descr: this.noJobData.Descr,
                        jobId: (this.setJobId != null ? this.setJobId : 0)                                     
                  }


                  console.log("----data----");
                  console.log(data);


      
                        adminService
                        .noJobEdit(data)
                        .then((response) => {
                              

                              if ((response.status === 200) & (response.data.Code === "OK")) {
                                    // console.log('response------');
                                    // console.log(response);

                                    this.showDialog = false;
                  
                                    console.log('--SAVED--');
                                    this.onChange();
                        
                              } 
                              else {
                                    this.error = response.data.Text;
                              }
                           

                        })
                        .catch((e) => {
                              this.error = e.message || "Something went wrong!";
                              if (e.response.status === 401) {
                              this.CookieRemove();
                              }
                        })
    


            }

            


      }

}
</script>

<style>


.j-item div {
    overflow-wrap: break-word;
    text-align: left;
    font-size: 12px;
    font-weight: 800;
    color: #c25a10;
}


.dialog-box.edit .nwd-wrap.j-wrap .f-item input {
    width: 215px;
    color: #667;
}

.dialog-box.edit .nwd-wrap.j-wrap .f-item.j-item input,
.dialog-box.edit .nwd-wrap.j-wrap .j-item .vue-select {
    width: 362px;
}


.dialog-box.edit .nwd-wrap.j-wrap .vue-select {
      margin: initial;
      margin: unset;
      width: 215px;
}



</style>