<template>
  <teleport to="body">
    <div v-if="show" @click="tryClose" class="backdrop"></div>
    
    <transition name="dialog">
      <dialog open v-if="show" class="dialog-box edit" :style="styleWidth">
        <header>
          <div v-if="showCloseIcon" class="icons">
                <button class="i-btn" @click="tryClose" title="Close"><i class="fas fa-times"></i></button>
          </div>
          <slot name="header">
            <h2>{{ title }}</h2>
          </slot>
        </header>
        <section>
          <slot name="content">
          </slot>
        </section>
        <menu v-if="!fixed">
          <slot name="actions">
            <base-button @click="tryClose">Close</base-button>
          </slot>
        </menu>
      </dialog>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showCloseIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    windowWidth: {
      type: Number,
      required: false,
      default: 576,
    },

  },
  computed: {
      styleWidth() {
        return {
          '--style-left': 'calc(50% - '+this.windowWidth/2+'px)',
          '--style-width': this.windowWidth+'px'
        }
      }
  },


  emits: ["close"],
  methods: {
    tryClose() {
      if (this.fixed) {
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 10vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  /* border-radius: 12px; */
  border: none;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26); */
  box-shadow: 5px 5px 9px -3px rgb(164 165 183);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: #fafafa;
}



header {
  background-color: #fff8e3;
  width: 100%;
  padding: 1rem;
  border-bottom: 1px solid #ffdb6e;
  position: relative;
}

header .icons {
  position: absolute;
  top: 8px;
  right: 46px;
}

header .icons i {
  font-size: 26px;
}

header .i-btn {
  width: 22px;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 3px;
  margin-left: 4px;
}

header h2 {
  margin: 0;
  color: #666;
  font-family: 'Anton-Regular';
  font-size: 24px;
  letter-spacing: .05em;
  text-transform: uppercase;
  font-weight: normal;

}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 18rem);
    width: 36rem;
  }

  dialog {
    left: var(--style-left);
    width: var(--style-width);
  }



}
</style>
