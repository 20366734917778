<template>
      <div class="rap-wrap" :class="showMenu ? 'show-rapmenu':'hide-rapmenu'">
            <div class="rap-panel">                  
                  <ul>                        
                         <li>
                              <router-link :to="{ name: 'Dashboard'}">
                              <button class="nav-btn" >Dashboard</button>
                              </router-link>
                        </li>
                         <li>
                              <router-link :to="{ name: 'UsersByMonths'}">
                              <button class="nav-btn" >Users by month</button>
                              </router-link>
                        </li>
                       
                        <li>
                              <router-link :to="{ name: 'JobsByMonth'}">
                              <button class="nav-btn" >Jobs by month</button>
                              </router-link>            
                        </li>
                        <li>
                            <router-link :to="{ name: 'ActiveNoJobsList'}">
                              <button class="nav-btn" >Active noJob list</button>
                            </router-link>
                         </li>
                        <li>
                              <router-link :to="{ name: 'RawData'}">
                              <button class="nav-btn" >All data to Excel</button>
                              </router-link>
                        </li>

                        <li v-if="uFnCheck(7)">
                              <router-link :to="{ name: 'UsersWork'}">
                              <button class="nav-btn" >Completed user work</button>
                              </router-link>
                        </li>
                         <li v-if="uFnCheck(8)">
                              <router-link :to="{ name: 'ProjectAmounts'}">
                              <button class="nav-btn" >Annual quota summary</button>
                              </router-link>
                        </li>
                        <li v-if="uFnCheck(9)">
                              <router-link :to="{ name: 'JobInfo'}">
                              <button class="nav-btn" >Job info</button>
                              </router-link>
                        </li>                        
                  </ul>
                 
            </div>
      </div>
</template>

<script>

import shared from "@/shared";

export default {
      props: {
               showMenu: Boolean
      },
      methods: {
                  
      },

      created(){
            this.uFnCheck = shared.uFnCheck;
   
      }
     
}
</script>

<style scoped>


.rap-wrap {
  
  display: block;
  width: 200px;
  background-color: white;
  box-shadow: 0px 3px 16px -5px rgb(120 122 123);
  z-index: 2;
  
}

.rap-wrap .rap-panel {
  border: 1px solid #ddd;
  padding-bottom: 30px;
}

.rap-panel .nav-btn {
  width: 92%;
  font-size: 12px;
  line-height: 14px;
  padding: 6px 4px;
  border: 1px solid #ddd;
}


.rap-wrap ul,
.rap-wrap ul li {
  padding: 0;
  margin:  0;
  list-style: none;
}

.rap-wrap ul {
  margin: 16px 0;
}

.rap-wrap ul li {
  padding: 4px 0;
  margin: 0 -1px;

}

.dark-mode .rap-wrap {
  
      background-color: #1c2630;
      box-shadow: 0px 3px 16px -5px rgb(0 0 0);
  
}

.dark-mode .rap-wrap .rap-panel {
    border: 1px solid #3e6487;
}

.dark-mode .rap-panel .nav-btn {
    border: 1px solid #5c5c5c;
}


</style>