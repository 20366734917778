import axios from 'axios';
import store from "../store";

const API_URL = store.getters.getApiUrl;


// axios.interceptors.request.use(function (config) {
//       // Do something before request is sent
//       store.commit('setAxiosState', 1);
//       return config;
//     }, function (error) {
//       // Do something with request error
//       return Promise.reject(error);
//     });

// axios.interceptors.response.use(function (response) {
//       // Any status code that lie within the range of 2xx cause this function to trigger
//       // Do something with response data
//       store.commit('setAxiosState', 0);
//       return response;
//     }, function (error) {
//       // Any status codes that falls outside the range of 2xx cause this function to trigger
//       // Do something with response error
//       return Promise.reject(error);
//     });


axios.interceptors.response.use(
      (response) => {
        let v = response.headers['version'] || '0.00';
        let ls = localStorage.getItem('version');
               
        if (ls === undefined || ls === null) {
            localStorage.setItem('version', v);
         
        }
        else if(v !== ls){
         localStorage.setItem('version', v);
         // store.commit('setVersion', v);
          window.location.reload(true); 
         }
          
      return Promise.resolve(response);
    })



export default {
      async list(date) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/list", date, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async get(id) {
            const headers = store.getters.getAuthHeader;
            const response = await axios.get(API_URL + "entries/" + id, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async add(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.post(API_URL + "entries/add", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async edit(entry) {
            entry.syslog = store.getters.userData.userName;
            const headers = store.getters.getAuthHeader;
            const response = await axios.put(API_URL + "entries/edit", entry, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async delete(id) {
            const headers = store.getters.getAuthHeader;
            const syslog = store.getters.userData.userName;
            const response = await axios.delete(API_URL + "entries/delete/" + id + "/" + syslog, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      },
      async dateSum(date) {
            const headers = store.getters.getAuthHeader;         
            const response = await axios.post(API_URL + "entries/date-sum", date, {headers});
            return {
                  'status': response.status,
                  'data': response.data
            };
      }
      
}