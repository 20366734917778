<template>
      <Loader :loader="loader" />

      <base-dialog :show="error != null" :showCloseIcon="false" title="Something Fishy" @close="error == null">
            <template v-slot:content>                  
                  <div class="inv-wrapper nwd-wrap">
                        <div class="inv-form-item">
                              <label>{{error}}</label>    
                        </div>
                  </div>
            </template>
             <template v-slot:actions>
                  <base-button class="d-btn d-edit" @click="error=null" title="Close"><i class="fas fa-times"></i></base-button>                  
            </template>
      </base-dialog>



      <div class="inner-wrap inner-wrap3 d-wrap">
            <h3>Dashboard</h3>
            <div class="criteria"> 

                  <div v-if="uFnCheck(11)" class="control-wrap">
                        <span>Agency: &nbsp;</span>
                        <vue-select 
                        v-model="agencyId" 
                        :options="agencies" 
                        label-by="Description" 
                        value-by="Id" 
                        :blur="onChange"
                        close-on-select />  
                             
                  </div>  
            </div>


            <div class="chart-0 chrt">
                  <div class="pan">

                        <BarChart 
                              :chartData="barData"
                              :options="barOptions"
                              :height="320"
                        />         

                  </div>                
            </div>


            <div class="chart-1 chrt">
                  <div class="left-pan">
                        <h5>User status</h5>
                        <vue-good-table
                              :columns="table1columns"
                              :rows="table1rows"
                              :line-numbers="false"
                              :search-options="{
                              enabled: false
                              }"
                              :sort-options="{enabled: false}"   
                                          
                        >
                              <template v-slot:emptystate>
                                    No data
                              </template>
                        
                        </vue-good-table>
                  

                  </div>
                  <div class="center-pan">

                        <DoughnutChart 
                              :chartData="donutData"
                              :options="donutOptions"
                              :height="240"
                        />  

                  </div> 

                  <div class="right-pan">
                        <h5>Missing jobs</h5>
                        <vue-good-table
                              :columns="table3columns"
                              :rows="table3rows"
                              :line-numbers="false"
                              :search-options="{
                              enabled: false
                              }"
                              :sort-options="{enabled: false}"   
                                          
                        >
                              <template v-slot:emptystate>
                                    No data
                              </template>
                        
                        </vue-good-table>
                  

                  </div>
            </div>

            <div class="chart-2 chrt">
                  <div class="left-pan">

                        <BarChart 
                              :chartData="bar2Data"
                              :options="bar2Options"
                              :height="320"
                        />       


                  </div>
                  <div class="right-pan">
                        <h5>Jobs Total</h5>
                        <vue-good-table
                              :columns="table2columns"
                              :rows="table2rows"
                              :line-numbers="false"
                              :search-options="{
                                    enabled: false
                              }"
                              :sort-options="{enabled: false}"   
                                          
                        >
                              <template v-slot:emptystate>
                                    No data
                              </template>
                        
                        </vue-good-table>
                  
                  

                  </div> 
            </div>





      </div>
  
</template>

<script>
import shared from "@/shared";
import adminService from "@/services/admin.js";
import Loader from '../../components/Loader.vue';
import { DoughnutChart } from 'vue-chart-3';
import { BarChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);


export default {
      components: {Loader, DoughnutChart, BarChart },
      name: "App",
    

      data: function () {
            return {
                  showNav: true,
                  error: null,
                  loader: false,
                  showDialog: false,
                  dialogTitle: '',
                  settDarkMode: false,
                  agencyId: this.$store.getters.userData.agencyId,
                  agencies: [],
                  posData: [],
                  statusData: [],
                  roleData: [],
                  teamData: [],
                  jobData: [],
                  noJobData: [],
                  barOptions: {
                        responsive: true,
                        plugins: {
                        legend: {
                              position: 'bottom',
                              display: false
                        },
                        title: {
                              display: true,
                              text: 'User job title',
                        },
                        },
                        scales: {
                              x: {
                              title: {
                              display: true,
                              text: 'Job title'
                              },
                              grid: {
                              color: '#545454'
                              }
                              },
                              y: {
                              title: {
                              display: true,
                              text: 'No.'
                              },
                              grid: {
                              color: '#545454'
                              }             
                              }
                        }
                  },
                  barData: {
                        labels: [],
                        datasets: [
                              {
                                    data: [],
                                    backgroundColor: ['#77CEFF'],
                                    borderColor: ['#77CEFF'],
                                    borderRadius: 2
                              }
                        ]
                  },
                  donutOptions: {
                        responsive: true,
                        plugins: {
                              legend: {
                                    position: 'bottom',
                              },
                              title: {
                                    display: true,
                                    text: 'User Level',
                              },
                        },
                  },
                  donutData: {
                        labels: [],
                        datasets: [
                              {
                                    data: [],
                                    backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#1cd4ce'],
                                    borderColor:  ['#fff']
                              }
                        ]
                  },
                  table1columns: [
                        {
                              label: 'Status',
                              field: 'Status',
                              tdClass: 'text-f14'
                        },
                        {
                              label: 'No.',
                              field: 'No',
                              tdClass: 'text-f14'
                              
                        },
                     
                  ],
                  table1rows: [],
                  bar2Options: {
                        responsive: true,
                        plugins: {
                        legend: {
                              position: 'bottom',
                              display: false
                        },
                        title: {
                              display: true,
                              text: 'Teams',
                        },
                        },
                        scales: {
                              x: {
                              title: {
                              display: true,
                              text: 'Team'
                              },
                              grid: {
                                    color: '#545454'
                              }
                              },
                              y: {
                                    title: {
                                    display: true,
                                    text: 'No.'
                              },
                              grid: {
                                    color: '#545454'
                              }             
                              }
                        }
                  },
                  bar2Data: {
                        labels: [],
                        datasets: [
                              {
                                    data: [],
                                    backgroundColor: ['#236e3f'],
                                    borderColor: ['#236e3f'],
                                    borderRadius: 2
                              }
                        ]
                  },
                  table2columns: [
                        {
                              label: 'Year',
                              field: 'JobYear',
                              tdClass: 'text-f14'
                        },
                        {
                              label: 'No.',
                              field: 'No',
                              tdClass: 'text-f14'
                              
                        },
                        {
                              label: 'with Owner No.',
                              field: 'HasOwnerNo',
                              tdClass: 'text-f14'
                              
                        },
                     
                  ],
                  table2rows: [],
                   table3columns: [
                        {
                              label: 'Status',
                              field: 'NoJobStatus',
                              tdClass: 'text-f14'
                        },
                        {
                              label: 'No.',
                              field: 'No',
                              tdClass: 'text-f14'
                              
                        },
                     
                  ],
                  table3rows: [],
            }
      },

      created(){
            this.uFnCheck = shared.uFnCheck;
            this.CookieRemove = shared.CookieRemove;
      },

      mounted() {
            this.CookieCheckGoToLogin = shared.CookieCheckGoToLogin;
            this.getSettingFromArr = shared.getSettingFromArr;
            this.setTheme = shared.setTheme;
            this.CookieCheckGoToLogin();
            
            


            
            this.settDarkMode = this.getSettingFromArr(this.$store.getters.userSettings, 8, true);
            this.setTheme(this.settDarkMode);

            if (this.settDarkMode) {
                  this.donutData.datasets[0].borderColor = ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED', '#1cd4ce'];
                  this.barOptions.scales.x.grid.color = '#545454';
                  this.barOptions.scales.y.grid.color = '#545454';
                  this.bar2Options.scales.x.grid.color = '#545454';
                  this.bar2Options.scales.y.grid.color = '#545454';
            }
            else {
                  this.donutData.datasets[0].borderColor = ['#fff'];
                  this.barOptions.scales.x.grid.color = '#d1d1d1';
                  this.barOptions.scales.y.grid.color = '#d1d1d1';
                  this.bar2Options.scales.x.grid.color = '#d1d1d1';
                  this.bar2Options.scales.y.grid.color = '#d1d1d1';
            }


            const data = {ResultName: "AgencyList"};
            adminService
                  .uniRap(data)
                  .then((response) => {

                  if (response.status === 200) {                  
            
                        this.agencies = response.data;    

                        

                         setTimeout(() => {                         
                              
                              this.agencyId = parseInt(this.$store.getters.userData.agencyId);
                              this.dashboardData();
                        },480);
                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";
                  
                  
                  if (e.response.status === 401) {
                        this.CookieRemove();
                  }
                  });

           

            
      },

      methods: {


            onChange() {

                  this.barData.labels = [];
                  this.barData.datasets[0].data = [];
                  
                  this.donutData.labels = [];
                  this.donutData.datasets[0].data = [];
              
                  this.bar2Data.labels = [];
                  this.bar2Data.datasets[0].data = [];

                  this.dashboardData();
            },

            dashboardData() {

                  adminService
                  .dashboard(this.agencyId)
                  .then((response) => {

                  if (response.status === 200) {    
                        
                              // console.log("----response.data----");  
                              // console.log(response.data); 

                              this.posData = response.data.Position;
                              this.statusData = response.data.Status;
                              this.roleData = response.data.Role;
                              this.teamData = response.data.Team;
                              this.jobData = response.data.JobYear;
                              this.noJobData = response.data.NoJobs;


                              this.posData.forEach(element => {
                                    this.barData.labels.push(element.Position);
                                    this.barData.datasets[0].data.push(element.No);                                    
                              });

                              this.roleData.forEach(element => {
                                    this.donutData.labels.push(element.Role);
                                    this.donutData.datasets[0].data.push(element.No);
                              });

                              this.table1rows = this.statusData;

                              this.teamData.forEach(element => {
                                    this.bar2Data.labels.push(element.Team);
                                    this.bar2Data.datasets[0].data.push(element.No);                                    
                              });

                              this.table2rows = this.jobData;
                              this.table3rows = this.noJobData;

                  }
                  
                  })
                  .catch((e) => {
                  this.error = e.message || "Something went wrong!";   
                  console.log("----błąd!!!!!!----");  
                  console.log(this.error);                        
                 
                  })
                  .finally(() => {
                  this.loader = false;
                  
                   
                  });


            }


      }

}
</script>

<style scoped>

      .inner-wrap .chart-0 {
            display: flex;
      }

      .inner-wrap .chart-0 .pan {
            width: 100%;
      }

      .inner-wrap .pan,
      .inner-wrap .center-pan
       {
            box-shadow: 0px 3px 16px -5px rgb(120 122 123);
            position: relative;
            background-color: white;
            margin: 10px 20px;
            padding: 18px;
      }

      .inner-wrap .center-pan {
            margin: 10px 10px;
      }

      .inner-wrap .chrt h5 {
            color: #666666;
            margin-top: 9px;
            margin-bottom: 10px;
      }


      .inner-wrap .chart-1.chrt .left-pan,
      .inner-wrap .chart-1.chrt .right-pan,
      .inner-wrap .chart-1.chrt .center-pan {
            width: 33.3%;
      }

      .inner-wrap .chart-2.chrt .left-pan {
            width: 66.6%;
      }

      .inner-wrap .chart-2.chrt .right-pan {
            width: 33.3%;
      }

      .inner-wrap3.d-wrap .vue-select {
            width: 350px;

      }


      .dark-mode .pan {
            box-shadow: 0px 3px 16px -5px rgb(24 24 24) !important;
            background-color: #1c2630 !important;
      
      }

</style>