<template>
  <main class="l-panel-h">

      <header>
            
            <h1>&nbsp;</h1>
            <!-- <div style="margin-top: -15px; margin-bottom: 18px; color: #707070;">tu będzie</div> -->
            <img src="../assets/lqt-logo-gr.png" />
      </header>

      <div class="lg-panel">
              <LoginPanel title="Sign in" />

            <div class="lg-access">
                  Don't have an account? 
                  <a href="mailto: aleksandra.jusinska@liquidthread.com">request access</a>
            </div>
      </div>

      <footer>
              <p>© 2024 Liquidthread</p>
      </footer>

  </main>
</template>

<script>
// @ is an alias to /src
import LoginPanel from "@/components/LoginPanel.vue";
import shared from "@/shared"

export default {
  name: "Login",
  components: {
    LoginPanel
  },
   created() {
     this.CookieCheckRedirect = shared.CookieCheckRedirect;
     
     this.CookieCheckRedirect("Home");
  },
  methods: { 
   }
};
</script>

<style scoped>

main {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      position: relative;
}

main::before {
      content: ' ';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
      background-image: url('../assets/login-bg.jpg');
      background-repeat: no-repeat;
      background-position: 0 86%;
      background-size: cover;
      z-index: -1;

}

main h1 {
      font-family: 'Anton-Regular';
      letter-spacing: 0.15em;
      color: #707070;
      margin-bottom: 6px;
      font-size: 3rem;
      margin-top: -16px;
}

main img {
      width: 73px;
      height: auto;
}

main .lg-panel {
      padding: 24px;
      min-width: 624px;
      min-height: 290px;
      background-color: #fff;
      box-shadow: 0px 3px 16px -5px rgb(120 122 123);
      position: relative;
      margin-top: -44px;
}

main .lg-panel .lg-access {
      position: absolute;
      width: 100%;
      bottom: 16px;
      left: 0;
      right: 0;
      margin: 0 auto;

      font-size: 14px;
}

main .lg-panel .lg-access a {
      color: inherit;
      text-decoration: none;

      background:
      linear-gradient(
            to right,
            rgba(255, 219, 110, 1),
            rgba(255, 219, 110, 1)
      ),
      linear-gradient(
            to right,
            rgba(255, 0, 0, 1),
            rgba(255, 0, 180, 1),
            rgba(0, 100, 200, 1)
      );
      background-size: 100% 2px, 0 2px;
      background-position: 100% 100%, 0 100%;
      background-repeat: no-repeat;
      transition: background-size 400ms;
}

main .lg-panel .lg-access a:hover {
      background-size: 0 2px, 100% 2px;
}


main footer {
      width: 100%;
      padding-top: 42px;
}

main footer p {
      margin-bottom: 48px;
}

@media (max-height: 680px) {

      main footer p {
            margin-bottom: 16px;
      }

}

@media (max-height: 880px) {

      main footer p {
            margin-bottom: 32px;
      }

}

.dark-mode main.l-panel-h::before {
      z-index: 0;

}

.dark-mode main .lg-panel {
      background-color: #1c2630;
}

.dark-mode .l-panel-h header {
    background-color: #2c3e50 !important;
}

</style>
